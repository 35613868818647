import React from "react";
import styles from "./Header.module.css";
import Header_Logo from "./Header_Logo";
import { Helmet } from "react-helmet";

function Header() {
  return (
    <>
      <Helmet>
        <title>Bard's Cafe | Dungeons & Dragons Podcast</title>
        <meta
          name="description"
          content="Welcome to Bard's Cafe, your source for Dungeons & Dragons (D&D) podcast. Join us for epic DnD adventures, discussions, and more!"
        />
      </Helmet>
      <header className={styles.headerBackdrop}>
        <div className={styles.headerRow}>
          <Header_Logo />
        </div>
      </header>
    </>
  );
}

export default Header;
