import StoryTile from "./StoryTile";
import React from "react";
import PlaceholderCover from "../../../images/D20_Initial.webp";

function Placeholder() {
  const storyCover = (
    <img
      src={PlaceholderCover}
      alt="Free Dungeons and Dragons Story | Faerun adventure | DnD | D&D | Dungeons & Dragons"
    />
  );

  const placeholderDesc =
    "The bard from this party...hasn't finished composing the story yet.";

  return (
    <StoryTile
      imageElement={storyCover}
      title="The Bard Died on this Adventure"
      description={placeholderDesc}
      storyLink="/"
    />
  );
}

export default Placeholder;
