import React from "react";
import { Helmet } from "react-helmet";
import styles from "./Hero_Banner.module.css";
import banner from "../../images/cafe_island.webp";
import youTube from "../../logos/yt_logo_only_mono_light.png";
import twitch from "../../logos/TwitchGlitchBlackOps.svg";
import podcast from "../../logos/Apple_Podcasts_Icon_blk_lg_060623.svg";
import spotify from "../../logos/Spotify_Logo_only_RGB_Black.png";
import Header from "../Header/Header";

function Hero_Banner() {
  return (
    <>
      <Helmet>
        <title>Bard's Cafe | Dungeons & Dragons Podcast</title>
        <meta
          name="description"
          content="Welcome to Bard's Cafe, your source for Dungeons & Dragons (D&D) podcast. Join us for epic DnD adventures, discussions, and more!"
        />
      </Helmet>
      <div className={styles.fullWidthImageContainer}>
        <img
          src={banner}
          alt="D&D | Dungeons & Dragons | DnD Podcast Ryme of the Frost Maiden - Bard's Cafe"
          className={styles.backgroundImage}
        />
        <div className={styles.overlay}>
          <Header />
        </div>
        <div className={styles.overlay_bards_cafe}>
          <h1>Bard's Cafe</h1>
        </div>
        <div className={styles.gradientOverlay}></div>
        {/* Navigation links */}
        <div className={styles.navigationLinks}>
          <a
            href="https://www.youtube.com/channel/UCGObXRQYIFkG15GySQMWbjA"
            target="_blank"
            className={styles.navLink}
          >
            <img
              src={youTube}
              alt="Dungeons & Dragons | D&D | DnD Bard's Cafe YouTube"
              className={styles.socialLogo}
            />
          </a>
          <a
            href="http://twitch.tv/bardscafe"
            target="_blank"
            className={styles.navLink}
          >
            <img
              src={twitch}
              alt="Dungeons & Dragons | D&D | DnD Bard's Cafe Twitch"
              className={styles.socialLogo}
            />
          </a>
          <a
            href="https://podcasts.apple.com/us/podcast/bards-cafe/id1728688137"
            target="_blank"
            className={styles.navLink}
          >
            <img
              src={podcast}
              alt="Dungeons & Dragons | D&D | DnD Bard's Cafe Podcast"
              className={styles.socialLogo}
            />
          </a>
          <a
            href="https://open.spotify.com/show/5sHqxcoUkFoJJqXE2XIENB"
            target="_blank"
            className={styles.navLink}
          >
            <img
              src={spotify}
              alt="Dungeons & Dragons | D&D | DnD Bard's Cafe Spotify"
              className={styles.socialLogo}
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default Hero_Banner;
