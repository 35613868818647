import React from "react";
import { Helmet } from "react-helmet";
import styles from "./LandingPage.module.css";

function LandingPage() {
  return (
    <>
      <Helmet>
        <title>Bard's Cafe | Dungeons & Dragons Podcast</title>
        <meta
          name="description"
          content="Welcome to Bard's Cafe, your ultimate source for Dungeons & Dragons (D&D) content. Join us for epic DnD adventures, real play podcasts, guides, and more. Let the dice fall as they may!"
        />
      </Helmet>
      <div className={styles.App}>
        <div className={styles.letter}>
          <p className={styles.big_stories}>We tell big stories.</p>
          <p>
            Stories that make you cry out with victory, and weep with grief from
            sudden and unexpected loss. Our stories are bigger than the
            characters in them. They are worlds full of life, and people, and
            good food.
          </p>
          <p>
            Our Tales are forged in dungeons, with dragons. The success of our
            ambitions and fate of our dreams are not fully up to us, or even our
            masters, but the dice of fate. Though not all our tales are found in
            dungeons. For treasure is often found upon a single piece of
            parchment.
          </p>
          <br />
          <p>We Share Legends,</p>
          <p className={styles.signature}>Bard's Cafe</p>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
