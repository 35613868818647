import StoryTile from "./StoryTile";
import React from "react";
import starlessSkyCover from "../../../images/stones_boat.webp";

function StarlessSky() {
  const storyCover = (
    <img
      src={starlessSkyCover}
      alt="Free Dungeons and Dragons Story | Faerun adventure | DnD | D&D | Dungeons & Dragons"
    />
  );

  const starlessSkyDesc =
    "NOT DONE. This story is a work in progress. A short story about an expedition from Silverymoon to the Ten Towns.";

  return (
    <StoryTile
      imageElement={storyCover}
      title="The Starless Sky"
      description={starlessSkyDesc}
      storyLink="/starlessSky"
    />
  );
}

export default StarlessSky;
